import React, { Component } from 'react';
import { Progress } from 'reactstrap';
import axios from 'axios';

export class FetchData extends Component {
    static displayName = FetchData.name;

    constructor(props) {
        super(props);
        this.state = {
            forecasts: [],
            loading: true,
            accountHealth: null,
            loadingAccountHealth: true
        };
    }

    componentDidMount() {
        this.populateWeatherData();
        this.fetchAccountHealth();
    }

    static renderForecastsTable(forecasts) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Temp. (C)</th>
                        <th>Temp. (F)</th>
                        <th>Summary</th>
                    </tr>
                </thead>
                <tbody>
                    {forecasts.map(forecast =>
                        <tr key={forecast.date}>
                            <td>{forecast.date}</td>
                            <td>{forecast.temperatureC}</td>
                            <td>{forecast.temperatureF}</td>
                            <td>{forecast.summary}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    async populateWeatherData() {
        const response = await fetch('weatherforecast');
        const data = await response.json();
        this.setState({ forecasts: data, loading: false });
    }

    async fetchAccountHealth() {
        try {
            const response = await axios.get('/accounthealth/account-health');
            const data = response.data;
            this.setState({ accountHealth: data, loadingAccountHealth: false });
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                console.error('Error response:', error.response.data);
                console.error('Status code:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received:', error.request);
            } else {
                // Something else happened while setting up the request
                console.error('Request setup error:', error.message);
            }
            // Handle error
        }
    }



    render() {
        const { loading, forecasts, loadingAccountHealth, accountHealth } = this.state;

        let progressBar;
        if (!loadingAccountHealth) {
            progressBar = (
                <div>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '40%', paddingRight: '40px' }}>
                                    <h6>Your overall health score</h6>
                                    <Progress
                                        animated
                                        color="success"
                                        striped
                                        value={accountHealth.overallHealthScore}> {accountHealth.overallHealthScore}%
                                    </Progress>
                                </td>
                                <td style={{ width: '30%', paddingRight: '20px' }} >
                                    <h6>Protection Installed</h6>
                                    <Progress
                                        animated
                                        color="success"
                                        striped
                                        value={accountHealth.protectionInstalledScore}> {accountHealth.protectionInstalledScore}%
                                    </Progress>
                                    <br></br>
                                    <h6>Tamper Protection</h6>
                                    <Progress
                                        animated
                                        color="success"
                                        striped
                                        value={accountHealth.tamperProtectionScore}> {accountHealth.tamperProtectionScore}%
                                    </Progress>
                                </td>
                                <td style={{ width: '30%', paddingRight: '20px' }} >
                                    <h6>Policies</h6>
                                    <Progress
                                        animated
                                        color="success"
                                        striped
                                        value={accountHealth.tamperProtectionScore}> {accountHealth.tamperProtectionScore}%
                                    </Progress>
                                    <br></br>
                                    <h6>Excusions</h6>
                                    <Progress
                                        animated
                                        color="success"
                                        striped
                                        value={accountHealth.tamperProtectionScore}> {accountHealth.tamperProtectionScore}%
                                    </Progress>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                   
                   
                    


                    <progress value={accountHealth.protectionEndpointsScore} max="100"></progress>
                    <br></br>
                    <progress value={accountHealth.protectionServerScore} max="100"></progress>
                    <br></br>
                    <progress value={accountHealth.tamperProtectionScore} max="100"></progress>
                    <br></br>
                    <progress value={accountHealth.tamperProtectionGlobalScore} max="100"></progress>
                    <br></br>
                    <progress value={accountHealth.tamperProtectionServerScore} max="100"></progress>
                </div>
            );
        } else {
            progressBar = <p>Loading account health...</p>;
        }

        return (
            <div>
                <h4 id="tabelLabel" >Account Health</h4>
                {progressBar}
            </div>
        );
    }
}
