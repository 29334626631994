import React, { useEffect, useState } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);

const Dashboard = () => {
    const [healthScores, setHealthScores] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchHealthScores = async () => {
            try {
                setLoading(true);

                const response = await fetch('/accounthealth/account-health');

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setHealthScores(data);
                setLoading(false);
            } catch (err) {
                setError("Error fetching data: " + err.message);
                setLoading(false);
            }
        };

        fetchHealthScores();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    if (!healthScores) {
        return <p>No data available</p>;
    }

    const dataBar = {
        labels: ['Overall', 'Protection Installed', 'Tamper Protection', 'Exclusions', 'Policy'],
        datasets: [
            {
                label: 'Health Scores',
                data: [
                    healthScores.overallHealthScore,
                    healthScores.protectionInstalledScore,
                    healthScores.tamperProtectionScore,
                    healthScores.exclusionsScore,
                    healthScores.policyScore,
                ],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(255, 159, 64, 0.6)',
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                ],
            },
        ],
    };

    const dataDoughnut = {
        labels: ['Overall', 'Protection Installed', 'Tamper Protection', 'Exclusions', 'Policy'],
        datasets: [
            {
                label: 'Health Scores',
                data: [
                    healthScores.overallHealthScore,
                    healthScores.protectionInstalledScore,
                    healthScores.tamperProtectionScore,
                    healthScores.exclusionsScore,
                    healthScores.policyScore,
                ],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(255, 159, 64, 0.6)',
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                ],
            },
        ],
    };

    return (
        <div style={{ width: '80%', margin: 'auto' }}>
            <h2>Health Score Dashboard</h2>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '45%' }}>
                    <h3>Health Scores Bar Chart</h3>
                    <Bar data={dataBar} options={{ responsive: true }} />
                </div>
                <div style={{ width: '45%' }}>
                    <h3>Health Scores Doughnut Chart</h3>
                    <Doughnut data={dataDoughnut} options={{ responsive: true }} />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
