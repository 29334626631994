import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { FaExclamationTriangle, FaCheckCircle, FaHourglassHalf } from 'react-icons/fa'; // Icons for statuses
import '../css/LicenseInfo.css';

const LicenseInfo = () => {
    const [licenseData, setLicenseData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchLicenseData = async () => {
            try {
                const response = await fetch("/accounthealth/get-licenses");

                if (!response.ok) {
                    setError('No endpoint data available');
                } else {
                    const data = await response.json();
                    const licenses = data.licenses.map((license) => {
                        const endDate = new Date(license.endDate);
                        const remainingDays = Math.ceil((endDate - new Date()) / (1000 * 60 * 60 * 24)); // Calculate remaining days

                        return {
                            name: license.product.name,
                            quantity: license.quantity,
                            count: license.usage ? license.usage.current.count : 0,
                            remaining: license.quantity - (license.usage ? license.usage.current.count : 0),
                            endDate: endDate.toLocaleDateString(),
                            remainingDays: remainingDays,
                            status: remainingDays < 30 ? (remainingDays <= 0 ? 'critical' : 'expiring-soon') : 'normal',
                        };
                    });

                    setLicenseData(licenses);
                }

                setLoading(false);
            } catch (err) {
                setError('Error fetching data');
                setLoading(false);
            }
        };

        fetchLicenseData();
    }, []);

    if (loading) {
        return (
            <div className="spinner-container">
                <PulseLoader size={15} color={"#36d7b7"} loading={true} />
            </div>
        );
    }

    if (error) return <div>{error}</div>;

    return (
        <div className="license-info-container">
            <h4>License Information</h4>
            <div className="license-cards">
                {licenseData.map((license, index) => (
                    <div className={`license-card ${license.status}`} key={index}>
                        <div className="icon-container">
                            {license.status === 'critical' && <FaExclamationTriangle className="status-icon critical-icon" size={10} />}
                            {license.status === 'expiring-soon' && <FaHourglassHalf className="status-icon warning-icon" size={30} />}
                            {license.status === 'normal' && <FaCheckCircle className="status-icon normal-icon" size={30} />}
                            
                        </div>
                        <h2>{license.name}</h2>
                        <div className="license-card-content">
                            <p><strong>Total Licenses:</strong> {license.quantity}</p>
                            <p><strong>Used Licenses:</strong> {license.count}</p>
                            <p><strong>Remaining Licenses:</strong> {license.remaining}</p>
                            <p><strong>Expires On:</strong> {license.endDate}</p>
                            <p className={`remaining-days ${license.remainingDays < 30 ? 'highlight' : ''}`}>
                                <strong>Remaining Days:</strong> {license.remainingDays} days left
                                {license.remainingDays < 30 ? (license.remainingDays <= 0 ? ' (Expired)' : ' (Renew Soon)') : ' (Active)'}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LicenseInfo;
