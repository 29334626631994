// AlertsDashboard.js

import React, { useState } from "react";
import '../css/AlertsDashboard.css';

const AlertsDashboard = ({ alertData }) => {
    const [selectedAlerts, setSelectedAlerts] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedAlertLabel, setselectedAlertLabel] = useState([]);
    const [selectedAlertIcon, setselectedAlertIcon] = useState([]);
    const [selectedAlertColor, setselectedAlertColor] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const alertsPerPage = 5;


    // Function to open popup with specific alerts
    const openPopup = (alerts, label, icon, gradient) => {
        setSelectedAlerts(alerts);
        setIsPopupOpen(true);
        setselectedAlertLabel(label);
        setselectedAlertIcon(icon);
        setselectedAlertColor(gradient);
        setCurrentPage(1);
    };

    // Function to close popup
    const closePopup = () => {
        setSelectedAlerts([]);
        setIsPopupOpen(false);
    };

    const totalAlerts = selectedAlerts.length;
    const totalPages = Math.ceil(totalAlerts / alertsPerPage);
    const startIndex = (currentPage - 1) * alertsPerPage;
    const currentAlerts = selectedAlerts.slice(startIndex, startIndex + alertsPerPage);

    const changePage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="alerts-dashboard">
            {alertData.map((alert, index) => (
                <div
                    className="alert-box"
                    key={index}
                    onClick={() => openPopup(alert.alerts, alert.label, alert.icon, alert.gradient)} // Open popup with relevant alerts
                >
                    <div className="alert-icon" style={{ background: alert.gradient }}>
                        <i className={`fas ${alert.icon}`}></i>
                    </div>
                    <div className="alert-count">{alert.count}</div>
                    <div className="alert-label">{alert.label}</div>
                </div>
            ))}

            {/* Popup */}
            {isPopupOpen && (
                <div className="popup-overlay" onClick={closePopup}>
                    <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                        <div className="popup-header">
                            <div className="alert-icon-popup" style={{ background: selectedAlertColor }}>
                                <i className={`fas ${selectedAlertIcon}`}></i>
                                </div>
                            <h3>{selectedAlertLabel}</h3>
                            <button className="popup-close" aria-label="Close" onClick={closePopup}>✖</button>
                        </div>
                        <div className="alerts-container widget widget-xlarge">
                            <table className="alerts-table">
                                <thead>
                                    <tr>
                                        <th>Date Raised</th>
                                        <th>Person</th>
                                        <th>Product</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentAlerts.map((alert, index) => (
                                        <tr key={index}>
                                            <td>{new Date(alert.raisedAt).toLocaleString()}</td>
                                            <td>{alert.person?.name || 'N/A'}</td>
                                            <td>{alert.product}</td>
                                            <td>{alert.description}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* Pagination Controls */}
                        <div className="pagination">
                            <button
                                onClick={() => changePage(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            <span>Page {currentPage} of {totalPages}</span>
                            <button
                                onClick={() => changePage(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AlertsDashboard;
