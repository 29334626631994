import React, { Component } from 'react';
import { Container } from 'reactstrap';
import logo from '../img/Guardin-header.png'; 

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
        <div className="dashboard-layout">
            {/* Header */}
            <header className="dashboard-header">
                <div className="logo">
                    <img src={logo} alt="Logo" style={{ height: '50px', marginRight: '20px' }} />
                </div>
            </header>
            <br></br>
            <br></br>
            <br></br>
            <div>
                <Container>
                {this.props.children}
                </Container>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <footer className="dashboard-footer">
                <p>2024 GuardianScope. All rights reserved.</p>
            </footer>
        </div>
    );
  }
}
