import React, { Component } from 'react';
import LicenseInfo from './LicenseInfo';
import EndpointInfo from "./EndpointInfo";

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div>
                <EndpointInfo />
                <LicenseInfo />
            </div>
        );
    }
}
