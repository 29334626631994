import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import LicenseInfo from "./components/LicenseInfo";
import EndpointInfo from "./components/EndpointInfo";
import OveralHealth from "./components/OveralHealth";


const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/fetch-data',
        element: <FetchData />
    },
    {
        path: '/license-info', // Add a new path for LicenseInfo
        element: <LicenseInfo /> // Add LicenseInfo component here
    },
    {
        path: '/enfpoint-info', // Add a new path for LicenseInfo
        element: <EndpointInfo /> // Add LicenseInfo component here
    },
    {
        path: '/overal-health', // Add a new path for LicenseInfo
        element: <OveralHealth /> // Add LicenseInfo component here
    }
];

export default AppRoutes;
