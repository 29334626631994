import React, { useState, useEffect } from 'react';
import { Bar, Pie, Doughnut } from 'react-chartjs-2';
import { PulseLoader } from 'react-spinners';
import '../css/EndpointInfo.css';
import AlertsDashboard from "./AlertsDashboard";
import { FaExclamationTriangle, FaCheckCircle, FaHourglassHalf } from 'react-icons/fa'; // Icons for statuses
import '../css/AlertsTable.css';

const EndpointList = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [alertItems, setalertItems] = useState([]);


    // List of services you want to monitor
    const servicesToMonitor = [
        "HitmanPro.Alert service",
        "Sophos Endpoint Defense",
        "Sophos Endpoint Defense Service",
        "Sophos File Scanner",
        "Sophos File Scanner Service",
        "Sophos MCS Agent",
        "Sophos MCS Client",
        "Sophos NetFilter",
        "Sophos Network Threat Protection",
        "Sophos System Protection Service"
    ];

    useEffect(() => {
        const fetchItems = async () => {
            try {

                //Get Endpoint Data
                const response = await fetch("/accounthealth/get-endpoints");
                const data = await response.json();

                if (data && data.items) {
                    setItems(data.items); // Set the items array
                } else {
                    setError('No endpoint data available');
                }

                //Get Alert Data
                const alertsAPIurl = await fetch("/accounthealth/get-alerts");
                const alertsData = await alertsAPIurl.json();

                if (alertsData && alertsData.items) {
                    setalertItems(alertsData.items);
                } else {
                    setError('No endpoint data available');
                }

                setLoading(false);
            } catch (err) {
                setError('Error fetching data');
                setLoading(false);
            }
        };

        fetchItems();
    }, []);

    // Conditional rendering based on loading and error states
    if (loading) {
        return (
            <div className="spinner-container">
                <PulseLoader size={15} color={"#36d7b7"} loading={true} />
            </div>
        );
    }
    if (error) return <div>{error}</div>;

    // Create a mapping of serviceName -> list of hostnames where the service is not running
    const serviceHostMap = {};
    servicesToMonitor.forEach(service => {
        serviceHostMap[service] = [];
    });

    // Loop through all items and their services
    items.forEach(item => {
        if (item.health && item.health.services && item.health.services.serviceDetails) {
            item.health.services.serviceDetails.forEach(serviceDetail => {
                if (servicesToMonitor.includes(serviceDetail.name) && serviceDetail.status !== 'running') {
                    serviceHostMap[serviceDetail.name].push(item.hostname);
                }
            });
        }
    });

    // Endpoint Overview
    const totalEndpoints = items.length;
    const endpointsWithIssues = items.filter(item =>
        item.health.services.serviceDetails.some(service => servicesToMonitor.includes(service.name) && service.status !== 'running')
    ).length;
    const healthyEndpoints = totalEndpoints - endpointsWithIssues;

    const barData = {
        labels: ['Total Endpoints', 'Healthy Endpoints', 'Endpoints with Issues'],
        datasets: [{
            label: 'Endpoints Overview',
            data: [totalEndpoints, healthyEndpoints, endpointsWithIssues],
            backgroundColor: ['#36A2EB', '#4BC0C0', '#FFCE56'], // Different colors for each bar
        }]
    };

    // Options for the bar chart
    const barOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
            datalabels: {
                color: 'black', // Color of the data labels
                display: true, // Show data labels
                anchor: 'end', // Positioning of the data labels
                align: 'end',
                formatter: (value) => value, // Show the value directly
            },
        },
        scales: {
            y: {
                beginAtZero: true, // Start y-axis at 0
                max: 10, // Set a fixed max for better visibility
                title: {
                    display: false,
                    text: 'Number of Endpoints', // Y-axis title
                },
            },
            x: {
                title: {
                    display: false,
                    text: 'Endpoint Categories', // X-axis title
                },
                ticks: {
                    autoSkip: false, // Prevent skipping of tick labels
                },
            },
        },
        barThickness: 40, // Set a fixed thickness for the bars
    };

    // Dynamic OS Distribution Calculation
    const osDistributionMap = {};
    items.forEach(item => {
        const osName = item.os?.name || "Unknown"; // Get OS name or default to "Unknown"
        osDistributionMap[osName] = (osDistributionMap[osName] || 0) + 1;
    });
    const osData = {
        labels: Object.keys(osDistributionMap),
        datasets: [{
            label: 'Operating System Distribution',
            data: Object.values(osDistributionMap),
            backgroundColor: ['#36A2EB', '#FFCE56', '#FF6384', '#FF9F40', '#4BC0C0']
        }]
    };

    // Dynamic Tamper Protection Status Calculation
    const tamperProtectionCount = { enabled: 0, disabled: 0 };
    items.forEach(item => {
        if (item.tamperProtectionEnabled) {
            tamperProtectionCount.enabled++;
        } else {
            tamperProtectionCount.disabled++;
        }
    });
    const tamperProtectionData = {
        labels: ['Enabled', 'Disabled'],
        datasets: [{
            data: [tamperProtectionCount.enabled, tamperProtectionCount.disabled],
            backgroundColor: ['#4BC0C0', '#FF6384']
        }]
    };

    // Dynamic Encryption Status Calculation
    const encryptionCount = { encrypted: 0, notEncrypted: 0 };
    items.forEach(item => {
        const volumes = item.encryption?.volumes || [];
        if (volumes.some(volume => volume.status === 'encrypted')) {
            encryptionCount.encrypted++;
        } else {
            encryptionCount.notEncrypted++;
        }
    });
    const encryptionData = {
        labels: ['Encrypted', 'Not Encrypted'],
        datasets: [{
            data: [encryptionCount.encrypted, encryptionCount.notEncrypted],
            backgroundColor: ['#36A2EB', '#FFCE56']
        }]
    };

    const totalAlertsCount = alertItems.length;
    const highAlerts = alertItems.filter(item => item.severity === "high");
    const highAlertsCount = highAlerts.length;
    const mediumAlerts = alertItems.filter(item => item.severity === "medium");
    const mediumAlertsCount = mediumAlerts.length;
    const lowAlerts = alertItems.filter(item => item.severity === "low");
    const lowAlertsCount = lowAlerts.length;


    const alertData = [
        { count: totalAlertsCount, label: 'Total Alerts', icon: 'fa-gem', color: 'blue', gradient: "linear-gradient(45deg, #1717b3, #00d4ff)", alerts: alertItems },
        { count: highAlertsCount, label: 'High Alerts', icon: 'fa-exclamation-circle', color: 'red', gradient: "linear-gradient(45deg, #ff4b2b, #ff416c)", alerts: highAlerts },
        { count: mediumAlertsCount, label: 'Medium Alerts', icon: 'fa-exclamation-triangle', color: 'orange', gradient: "linear-gradient(45deg, #ffba00, #fcb045)", alerts: mediumAlerts },
        { count: lowAlertsCount, label: 'Low Alerts', icon: 'fa-info-circle', color: 'gray', gradient: "linear-gradient(45deg, #32a852, #00bfa5)", alerts: lowAlerts },
    ];

    // Sort by raisedAt date and get the last 5 alerts
    const lastFiveAlerts = alertItems
        .sort((a, b) => new Date(b.raisedAt) - new Date(a.raisedAt)) // Sort descending by raisedAt
        .slice(0, 5);

    const getIconClass = (type) => {
        switch (type) {
            case 'high':
                return 'fas fa-exclamation-circle'; // Font Awesome class for error
            case 'Low':
                return 'fas fa-info-circle'; // Font Awesome class for info
            case 'medium':
                return 'fas fa-exclamation-triangle'; // Font Awesome class for warning
            default:
                return '';
        }
    };

    const getIconColor = (type) => {
        switch (type) {
            case 'high':
                return 'red';
            case 'Low':
                return 'gray';
            case 'medium':
                return 'orange';
            default:
                return 'black';
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    };

    return (
        <div className="dashboard-container">
            <AlertsDashboard alertData={alertData} />

           {/* <h4>Endpoint Services Health Dashboard</h4>*/}
            <div className="dashboard-grid">
                 {/* Endpoint Overview */}
                <div className="widget widget-large">
                    <h2>Endpoint Overview</h2>
                    <Bar data={barData} options={barOptions} />
                </div>

                {/* OS Distribution */}
                <div className="widget small-chart-widget">
                    <h2>OS Distribution</h2>
                    <Pie
                        data={osData}
                        options={{
                            maintainAspectRatio: true,
                            plugins: {
                                legend: {
                                    display: false // Disable the legend
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function (tooltipItem) {
                                            const label = tooltipItem.label; // Get the label (OS name)
                                            const value = tooltipItem.raw; // Get the value (count)
                                            return `${label}: ${value}`; // Return formatted label with value
                                        }
                                    }
                                }
                            }
                        }}
                    />
                </div>

                {/* Tamper Protection Status */}
                <div className="widget small-chart-widget">
                    <h2>Tamper Protection</h2>
                    <Doughnut data={tamperProtectionData} options={{ maintainAspectRatio: false }} />
                </div>

                {/* Endpoint Encryption Status */}
                <div className="widget small-chart-widget">
                    <h2>Encryption Status</h2>
                    <Doughnut data={encryptionData} options={{ maintainAspectRatio: false }} />
                </div>

                {/* Most Recent Alerts Table */}
                <div className="alerts-container widget widget-xlarge">
                    <h2>Most Recent Alerts</h2>
                    <table className="alerts-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Date</th>
                                <th>Message</th>
                                <th>Device</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lastFiveAlerts.map((alert, index) => (
                                <tr key={index} className={`alert-${alert.severity}`}>
                                    <td>
                                        <i
                                            className={getIconClass(alert.severity)}
                                            style={{ color: getIconColor(alert.severity), fontSize: '18px' }}
                                        ></i>
                                    </td>
                                    <td>{formatDate(alert.raisedAt)}</td>
                                    <td>{alert.description}</td>
                                    <td>{alert.person.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <br></br>
            {/* Service Health Monitoring */}
            <div className="service-grid">
                {servicesToMonitor.map(service => (
                    <div key={service} className={`service-box ${serviceHostMap[service].length > 0 ? 'problematic' : 'healthy'}`}>
                        <div className="icon-container">
                            {serviceHostMap[service].length > 0 && <FaExclamationTriangle className="status-icon critical-icon" size={30} />}
                            {serviceHostMap[service].length == 0 && <FaCheckCircle className="status-icon normal-icon" size={30} />}
                        </div>
                        <h2>{service}</h2>
                        {serviceHostMap[service].length > 0 ? (
                            <div>
                                <p>Issues detected on:</p>
                                <ul>
                                    {serviceHostMap[service].map((hostname, index) => (
                                        <li key={index}>{hostname}</li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <p>All endpoints running this service without issues.</p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EndpointList;
